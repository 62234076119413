
<script>
export default {
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            counter : 0
        };
    },
    methods:{
      cancel(){
          this.$root.$emit('bv::hide::modal', 'modalDeleteForever');
      },
      deleteForever(id){
        this.$store.dispatch("storage/deleteForever",id)
        .then(()=> {
          this.$toasted.show(`Item deleted permanently.`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        })
        .catch(()=> {
          this.$toasted.show(`Item failed to delete permanently. Make sure your internet is connected, then try again.`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        })
        this.$root.$emit('bv::hide::modal', 'modalDeleteForever');
      }
    }
};
</script>
<template>
    <b-modal id="modalDeleteForever" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Delete Forever ?">
        <div v-if="item != null">
            <h2 class="modal-title text-dark default-normal-size weight-500 black-color">Delete 1 item permanently?</h2>
            <p class="mt-3 default-normal-size black-color">This item will be deleted permanently and you won't be able to restore it.</p>
            <div class="btn-modal-encrypt align-items-center">
                <a href="javascript:void();" @click="cancel()" class="default-normal-size weight-500"><span class="main-color-api">Cancel</span></a>
                <button class="btn-delete ml-4 " @click="deleteForever(item._id)">
                    <span class="default-normal-size weight-500">Delete Permanently</span>
                </button>
            </div>
        </div>
    </b-modal>
</template>